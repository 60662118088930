<template>
  <loading :active="isLoading" :can-cancel="true" :on-cancel="onCancel" :z-index="10000" :color="'var(--First-Color)'">
  </loading>
  <Header />
  <Home_Component />
  <section class="vacancy_result">
    <div class="container">
      <div class="row">
        <div class="col-12">

          <div class="title-sect">
            <div class="title-box">
              <h4 class="title">ძიების შედეგი</h4>
              <h4 class="vacancy-calc">({{ ResponseData.total }} ვაკანსია)</h4>
            </div>
          </div>

          <!-- Col -->
          <div class="col-12">
            <div class="info-box">
              <div v-for="item in ResponseData.data" :key="item.id"
                :id="item.super_vip_status === 'active' ? 'super_vip' : '' || item.vip_status === 'active' ? 'vip' : '' || this.$route.query.id === '286' ? 'gold_list' : ''"
                class="info">
                <div class="section-one">
                  <router-link :to="'/VacancyDetails/' + item.id">
                    <img v-if="item.CompanyDetail.logo != ''" class="company-logo" :src="item.CompanyDetail.logo"
                      alt="vacancy logo">
                    <img v-else class="company-logo" src="../../assets/image/noavatar.jpeg" alt="vacancy logo">
                  </router-link>
                  <div class="inside-box">
                    <router-link :to="'/VacancyDetails/' + item.id">
                      <h6>{{ item.PositionDetail.text }}</h6>
                    </router-link>
                    <router-link :to="'/VacancyDetails/' + item.id">
                      <p :title="item.IndustryDetail.text">{{ item.IndustryDetail.text }}</p>
                    </router-link>
                    <router-link :to="'/Company/' + item.CompanyDetail.id">
                      <p class="company-link">{{ item.CompanyDetail.brandname }}</p>
                    </router-link>
                  </div>
                </div>
                <router-link :to="'/VacancyDetails/' + item.id" class="section-two">
                  <div class="col-5">
                    <div class="inside-box-two">
                      <img src="../../assets/image/icons/location-icon.svg" alt="">
                      <p>{{ item.VacancyLocation.name }}</p>
                    </div>
                  </div>
                  <div class="col-7">
                    <div class="inside-box-two">
                      <img src="../../assets/image/icons/experience-icon.svg" alt="">
                      <p v-if="item.work_experience == '1'">გამოცდილების გარეშე</p>
                      <p v-if="item.work_experience == '2'">დამწყები</p>
                      <p v-if="item.work_experience == '3'">საშუალო დონე</p>
                      <p v-if="item.work_experience == '4'">პროფესიონალი</p>
                    </div>
                  </div>
                  <div class="col-5">
                    <div class="inside-box-special">
                      <img src="../../assets/image/icons/lari-icon.svg" alt="">
                      <p v-if="item.amount_type == '1'">{{ Number(item.avarage_min_bonus).toLocaleString()
                        }}{{ item.CurrencyDetail.text }} - {{ Number(item.avarage_max_bonus).toLocaleString()
                        }}{{ item.CurrencyDetail.text }}</p>
                      <p v-if="item.amount_type == '2'">{{ Number(item.min_amount).toLocaleString() }}{{
                        item.CurrencyDetail.text }} - {{ Number(item.max_amount).toLocaleString() }}{{
                          item.CurrencyDetail.text }}</p>
                      <p v-if="item.amount_type == '3'">{{ Number(item.fixed_amount).toLocaleString() }}{{
                        item.CurrencyDetail.text }}</p>
                    </div>
                  </div>
                  <div class="col-7">
                    <div class="inside-box-special">
                      <img src="../../assets/image/icons/time-progress-icon.svg" alt="">
                      <p>{{ item.work_schedule.name }}</p>
                    </div>
                  </div>
                </router-link>
              </div>

            </div>
          </div>
          <!-- Col -->
          <div class="col-12 d-flex justify-content-center" v-if="ResponseData.data == ''">
            <img src="../../assets/image/training/noResult.svg" class="img-fluid w-50">
          </div>
          <!-- Col -->
          <div class="col-12 d-flex justify-content-center">
            <nav class="pagination-container">
              <Pagination :limit="2" show-disabled :data="ResponseData" @pagination-change-page="getResults">
                <template #prev-nav>
                  <span>წინ</span>
                </template>
                <template #next-nav>
                  <span>შემდეგ</span>
                </template>
              </Pagination>

              <!--              <ul class="pagination">-->
              <!--                <li class="page-item">-->
              <!--                  <a class="page-link" href="#" aria-label="Previous">-->
              <!--                    <span class="sr-only">წინ</span>-->
              <!--                  </a>-->
              <!--                </li>-->
              <!--                <li class="page-item active"><a class="page-link" href="#">1</a></li>-->
              <!--                <li class="page-item"><a class="page-link" href="#">2</a></li>-->
              <!--                <li class="page-item"><a class="page-link" href="#">3</a></li>-->
              <!--                <li class="page-item">-->
              <!--                  <a class="page-link" href="#" aria-label="Next">-->
              <!--                    <span class="sr-only">შემდეგ</span>-->
              <!--                  </a>-->
              <!--                </li>-->
              <!--              </ul>-->
            </nav>

          </div>
          <!-- End Col -->
        </div>
      </div>
    </div>
  </section>
  <Footer />
</template>

<script>
import Header from '@/components/Header.vue'
import Home_Component from '@/components/Home.vue'
import Footer from '@/components/NewFooter.vue'

import Loading from 'vue3-loading-overlay';
import LaravelVuePagination from 'laravel-vue-pagination';

export default {
  name: 'VacancyList',
  data() {
    return {
      isLoading: true,
      ResponseData: {},
      form: {
        searchKeyword: this.$route.query.searchKeyword,
        region: this.$route.query.region,
        minPrice: this.$route.query.minPrice,
        maxPrice: this.$route.query.maxPrice,
        id: this.$route.query.id,
        type: this.$route.query.type,
      },
      page: this.$route.query.page
    }
  },
  components: {
    Header,
    Home_Component,
    Footer,
    Loading,
    'Pagination': LaravelVuePagination
  },
  methods: {
    onCancel() {

    },
    getResults(page = this.page) {

      this.$router.replace({
        path: '/VacancyList',
        query: {
          searchKeyword: this.form.searchKeyword,
          region: this.form.region,
          minPrice: this.form.minPrice,
          maxPrice: this.form.maxPrice,
          id: this.form.id,
          type: this.form.type,
          page: page
        }
      })


      this.isLoading = true;
      this.axios.get('https://recruting.dkcapital.ge/api/public/vacancy/v2/search' + '?page=' + page, {
        params: {
          searchKeyword: this.$route.query.searchKeyword,
          region: this.$route.query.region,
          minPrice: this.$route.query.minPrice,
          maxPrice: this.$route.query.maxPrice,
          id: this.$route.query.id,
          type: this.$route.query.type
        }
      })
        .then(async response => {
          this.ResponseData = await response.data.vacancy;
          this.isLoading = false;
          window.scroll(0, 450);

        });
    }
  },

  mounted() {

    // Fetch initial results
    this.getResults();

  }
}
</script>

<style scoped>
h4 {
  margin-bottom: 0;
}

a {
  text-decoration: none;
}

.inside-box a:nth-child(2) {
  overflow: hidden;
}

.inside-box a:nth-child(2) p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

h6 {
  font-family: var(--First-Font);
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  margin: 0;

  color: #424242;

}

p {
  font-family: var(--Third-Font);
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0;

  color: rgba(66, 66, 66, 0.5);
}

.company-link {
  font-family: var(--Third-Font);
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0em;

  color: #2200CC;
}

.title-sect {
  display: grid;
  gap: 16px;
  padding-bottom: 36px;
}

.title-box {
  display: flex;
  gap: 16px;
  flex-direction: row;
  align-items: flex-end;
}

.title {
  max-width: 138px;
  width: 100%;
  font-family: var(--First-Font);
  font-size: 18px;
  line-height: 21.6px;
  font-weight: 500;

  font-feature-settings: 'case' on;
  color: #424242;

}

.vacancy-calc {
  /*max-width: 88px;*/
  width: 100%;
  font-family: var(--First-Font);
  font-size: 13px;
  line-height: 21px;
  font-weight: 500;

  color: rgba(66, 66, 66, 0.5);
}

.info-box {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
}

.info {
  display: grid;
  gap: 8px;

  padding: 18px 18px 22px;
  border: 1px solid rgba(66, 66, 66, 0.1);
  border-radius: 10px;
  transition: all 0.3s;

}

.info h6 {
  width: 90%;
}

.info:hover {
  border: 1px solid var(--Second-Color-Box-Hover);
  transform: translateY(-4px);
}

.inside-box {
  display: grid;
  gap: 5px;
}

.inside-box-two {
  display: flex;
  align-items: center;
  gap: 9.04px;
}

.inside-box-special {
  margin-top: 10px;
  display: flex;
  align-items: center;
  gap: 9px;
}

.section-one {
  display: flex;
  align-items: start;
  height: 100px;
  gap: 16px;

}

.section-two {
  display: flex;
  flex-wrap: wrap;
}

.company-logo {
  width: 80px;
  /*height: 57px;*/
  /*border: 1px solid rgba(66, 66, 66, 0.1);*/
  /*border-radius: 50%;*/
  /*object-fit: cover;*/
  aspect-ratio: 1/1;
  object-fit: contain;
  object-position: top;
}

.search-alert {
  font-family: var(--Third-Font);
  font-size: 16px;
}

@media screen and (max-width:1399px) {
  .info-box {
    grid-template-columns: 1fr 1fr 1fr;
    width: 100%;
    gap: 18px;
  }

  .info {
    gap: 8px;
    padding: 21px 20px;
  }

  .inside-box-special {
    margin-top: 10px;
  }

  p {
    font-size: 13px;
  }

  @media screen and (max-width:1200px) {
    .info-box {
      grid-template-columns: 1fr 1fr;
      width: 100%;
      gap: 18px;
    }

    .info {
      gap: 8px;
      padding: 21px 18px;
    }

    .inside-box-special {
      margin-top: 10px;
    }

    p {
      font-size: 13px;
    }
  }

  @media screen and (max-width:991px) {
    .title-sect {
      padding-right: 18px;
    }

    .info-box {
      grid-template-columns: 1fr 1fr;
      width: 100%;
      gap: 14px;
    }

    .info {
      gap: 8px;
      padding: 21px 18px;
    }

    .inside-box-special {
      margin-top: 10px;
    }

    p {
      font-size: 12px;
    }
  }

}

@media screen and (max-width:768px) {
  .info-box {
    grid-template-columns: 100%;
    width: 100%;
    gap: 16px;
  }

  .info {
    gap: 8px;
    padding-top: 21px;
    padding-bottom: 21px;
  }

  .inside-box-special {
    margin-top: 10px;
  }

  p {
    font-size: 13px;
  }
}

@media screen and (max-width:428px) {
  .info-box {
    grid-template-columns: 100%;
    width: 100%;
    gap: 16px;
  }

  .info {
    gap: 8px;
    padding: 21px 23px 21px 25px;
  }

  .inside-box-special {
    margin-top: 10px;
  }

  p {
    font-size: 13px;
  }
}

@media screen and (max-width:375px) {
  .title-sect {
    padding-left: 0;
    padding-right: 0;
  }

  .info-box {
    grid-template-columns: 100%;
    width: 100%;
    padding-right: 0;
    padding-left: 0;
    gap: 16px;
  }

  .info {
    gap: 8px;
    padding: 21px 13px;
  }

  .inside-box-special {
    margin-top: 10px;
  }

  .company-link {
    font-size: 12px;
  }

  h6 {
    font-size: 13px;
  }

  p {
    font-size: 12px;
  }

  .company-logo {
    width: 60px;
    /*height: 50px;*/
  }
}

@media screen and (max-width:320px) {
  .title-sect {
    padding-left: 0;
    padding-right: 0;
  }

  .info-box {
    grid-template-columns: 100%;
    width: 100%;
    padding-right: 0;
    padding-left: 0;
    gap: 16px;
  }

  .info {
    gap: 8px;
    padding: 21px 10px;
  }

  .inside-box-special {
    margin-top: 10px;
  }

  .company-link {
    font-size: 11px;
  }

  h6 {
    font-size: 13px;
  }

  p {
    font-size: 11px;
  }

  .company-logo {
    width: 50px;
    height: 50px;
  }
}


/* Footer */
.footer {
  margin-top: 106px;
}


#super_vip.info {
  border: 1px solid #ffb800;
  position: relative;
  overflow: hidden;


  background-color: #ffb70010;
}

#super_vip.info::before {
  content: "";
  width: 45px;
  height: 45px;
  background-image: url('../../assets/icons/Super_Vip_noBG.png');
  background-size: 50%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-color: #ffb800;
  /* border-top-left-radius: 10px; */
  border-bottom-left-radius: 50%;
  /* border-bottom-right-radius: 10px; */


  position: absolute;
  top: 0;
  right: 0;
}


#vip.info {
  border: 1px solid var(--Fourth-Color);
  position: relative;
  overflow: hidden;


  background-color: #246af609;
}

#vip.info::before {
  content: "";
  width: 45px;
  height: 45px;
  background-image: url('../../assets/icons/VIP_noBG.png');
  background-size: 50%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-color: var(--Fourth-Color);
  /* border-top-left-radius: 50%; */
  border-bottom-left-radius: 50%;
  /* border-bottom-right-radius: 50%; */


  position: absolute;
  top: 0;
  right: 0;
}


#gold_list.info {
  border: 1px solid #ffb800;
  position: relative;
  overflow: hidden;


  background-color: #ffb70010;
}

#gold_list.info::before {
  content: "";
  width: 45px;
  height: 45px;
  background-image: url('../../assets/icons/VIP_noBG.png');
  background-size: 50%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-color: #ffb800;
  /* border-top-left-radius: 50%; */
  border-bottom-left-radius: 50%;
  /* border-bottom-right-radius: 50%; */


  position: absolute;
  top: 0;
  right: 0;
}
</style>
